.contacts-line {
  font-size: 16px;
  line-height: 24px;
  &:first-of-type {
    margin-top: 8px;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  a, span {
    font-weight: 600;
    color: var(--color-primary);
    word-break: break-word;
  }
}

.contact-section {
  @include max-991 {
    display: flex;
    flex-direction: column;
    padding-top: 42px;
    padding-bottom: 0;
  }
  &__inner {
    @include max-991 {
      order: 1;
    }
  }
  &__img-wrap {
    @include max-991 {
      order: 2;
    }
  }
}

.footer {
  @include min-992 {
    padding: 8px 0;
  }
  @include max-991 {
    padding: 20px 0 40px 0;
  }
  &__inner {
    display: flex;
    @include min-992 {
      align-items: center;
      justify-content: space-between;
    }
    @include max-991 {
      align-items: center;
      flex-direction: column;
    }
  }
  &__copyright {
    font-size: 12px;
    line-height: 18px;
    @include max-991 {
      order: 3;
      text-align: center;
    }
  }
  &__nav {
    list-style-type: none;
    @include max-991 {
      display: none;
    }
    @include min-992 {
      display: flex;
      align-items: center;
    }
    &-item {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
    &-link {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-primary);
    }
  }
  .footer__nav {
    @include max-991 {
      display: block;
    }
  }
  .footer__nav-item {
    @include max-991 {
      margin-right: 0px;
      text-align: center;
    }
  }
  .footer__nav-item:last-child {
    @include max-991 {
      margin-bottom: 20px;
    }
  }
  &__logos {
    list-style-type: none;
    display: flex;
    align-items: center;
    @include max-991 {
      order: 2;
      margin-bottom: 24px;
    }
    &-item {
      &:not(:last-child) {
        margin-right: 3px;
      }
    }
    &-img {
      width: auto;
      height: auto;
    }
  }
  &__social {
    @include max-991 {
      order: 1;
      margin-bottom: 24px;
    }
  }
}

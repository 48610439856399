.benefits-section {
  background-color: #E6DFD5;
  @include min-1201 {
    padding-top: 151px;
  }
  @include min-992 {
    padding-top: 92px;
  }
  @include max-991 {
    padding-bottom: 78px;
  }
  &__container {
    @include min-992 {
      display: flex;
    }
  }
  &__text {
    max-width: 404px;
    font-size: 20px;
    line-height: 32px;
    padding: 0 21px;
  }
  &__left {
    @include min-992 {
      width: 50%;
    }
  }
  &__right {
    @include min-992 {
      max-width: 332px;
      flex: 1;
    }
  }
  &__title {
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 19px;
  }
  &__img {
    width: 100%;
    height: auto;
  }
}
.benefits-list {
  list-style-type: none;
  &__item {
    padding: 20px;
    border-radius: 16px;
    background-color: var(--color-white);
    font-size: 16px;
    line-height: 20px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.team {
  @include min-768 {
    padding: 69px 0 99px 0;
  }
  @include max-767 {
    padding: 42px 0 59px 0;
  }
  &__title {
    font-weight: 600;
    line-height: 32px;
  }
}
.team-grid {
  margin-top: 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @include min-768 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
  @include min-1025 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &__item {
    border-radius: 16px;
    overflow: hidden;
    text-align: center;
  }
  &__img {
    display: flex;
    width: 100%;
    height: 165px;
    object-fit: cover;
    object-position: top;
    @include min-561 {
      height: 300px;
    }
    @include min-768 {
      height: 240px;
    }
  }
  &__info {
    background-color: #F1F1F1;
    padding: 16px 0;
    & > *:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
  &__position {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #8C8C8C;
  }
}

.products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 10px;

  align-items: center;
  justify-items: center;
  &__item {
    width: auto;
    height: auto;
  }
}

.accordion{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  .accordion__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .accordion__btn{
          margin: 0;
          position: relative;
          padding: 16px 28px 16px 48px;
          width: 100%;
          color: var(--color-black);
          display: block;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          background-color: #ECF7FC;
          cursor: pointer;
          transition: var(--transition);
          border-radius: 20px;
          @include max-560 {

          }
          span{
              display: block;
              position: absolute;
              height: 11px;
              width: 11px;
              left: 21px;
              top: 25px;
              transform: translateY(-50%) rotate(-45deg);
              border-bottom: 3px solid var(--color-black);
              border-left: 3px solid var(--color-black);
              transition: var(--transition);
              border-radius: 1px;
              pointer-events: none;
              @include max-560 {

              }
          }

      }
      .accordion__panel{
        width: 100%;
        color: var(--color-black);
        background-color: #ECF7FC;
        font-size: 16px;
        line-height: 24px;
        padding: 0 24px 0 48px;
        transition: var(--transition);
        opacity: 0;
        height: auto;
        max-height: 0;
        overflow: hidden;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
      }


      &.active{
          .accordion__btn{
              color: var(--color-primary);
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
              span{
                transform: translateY(-50%) rotate(135deg);
                border-color: var(--color-primary);
                top: 31px;
            }
          }
          .accordion__panel{
              padding-bottom: 24px;
              opacity: 1;
              max-height: 500px;
          }
      }
  }
}

.btn {
  border: 1px solid transparent;
  color: var(--color-white);
  border-radius: 34px;
  padding: 12px 28px;
  cursor: pointer;
  display: inline-block;
  transition: var(--transition);
  position: relative;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  background-color: var(--color-black);
  &--primary {
    background-color: var(--color-primary);
    &:hover {

    }
  }
  &--secondary {
    background-color: var(--color-secondary);
    &:hover {

    }
  }
  &[disabled] {
    background: linear-gradient(86.69deg, #F0F3F6 0%, #F0F3F6 116.5%);
    color: #CACDD6;
    pointer-events: none;
  }
}

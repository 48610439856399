.services-dropdown  {
  margin-top: 16px;
  margin-bottom: 6px;
}
.area-img {
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
  width: auto;
  height: auto;
  @include min-1601 {
    width: 800px;
  }
  @include max-1600 {
    width: 500px;
  }
  @include min-992 {

  }
  @include max-991 {
    margin-bottom: 6px;
  }
}
.section-main__right {
  position: relative;
}
.service-section:not(.service-section--single) {
  @include max-991 {
    background-color: #DCE7EC;
    padding-bottom: 42px;
    margin-top: -1px;
    .section-main__content {
      padding: 28px 28px 8px 28px;
      background-color: var(--color-white);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .section-main__right {
      background-color: var(--color-white);
      padding: 8px 28px 28px 28px;
      margin-top: -1px;
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

}
.service-section--single {
  @include max-991 {
    padding: 82px 0 72px 0;
    .services-dropdown {
      margin-bottom: 0;
    }
    .section-main__btn {
      margin-bottom: 27px;
    }
  }
}

.faq {
  @include min-768 {
    padding: 49px 0 137px 0;
  }
  @include max-767 {
    padding: 28px 0 103px 0;
  }
  &__container {

  }
  &__title {
    margin-bottom: 20px;
  }
}

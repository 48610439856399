/* The container must be positioned relative: */
.dropdown {
  position: relative;
  min-width: 145px;
  width: fit-content;
}

.dropdown select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: var(--color-white);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 34px;
  padding: 12px 38px 12px 16px;
  transition: var(--transition);
}

/* Style the arrow inside the select element: */
.select-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 15px;
  width: 9px;
  height: 9px;

  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 3px solid var(--color-primary);
  border-left: 3px solid var(--color-primary);
  transition: var(--transition);
  border-radius: 1px;

  margin-top: -1px;
}
.select-selected.select-arrow-active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  transform: translateY(-50%) rotate(135deg);
  border-color: var(--color-white);
  margin-top: 1px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  // padding: 6px 26px 6px 8px;
  cursor: pointer;
}
.select-items div {
  padding: 10px 20px;
  white-space: nowrap;
  background-color: var(--color-white);
  font-size: 16px;
  line-height: 16px;
}
/* Style items (options): */
.select-items {
  position: absolute;
  background-color: var(--color-white);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 0;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.select-items .same-as-selected {
  background-color: var(--color-primary);
  color: var(--color-white)
}

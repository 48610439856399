/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
:root {
  --font-family: "Goldplay", sans-serif;
  --font-size: 16px;
  --content-width: 1120px;
  --content-sm-width: 1024px;
  --content-lg-width: 1850px;
  --container-offset: 15px;
  --container-sm-offset: 28px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-sm-width: calc(var(--content-sm-width) + (var(--container-sm-offset) * 2));
  --container-lg-width: calc(var(--content-lg-width) + (var(--container-offset) * 2));
  --transition: all .2s ease-in;
  --header-height: 80px;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary: #40B2E6;
  --color-secondary: #F6894E;
}

.btn {
  border: 1px solid transparent;
  color: var(--color-white);
  border-radius: 34px;
  padding: 12px 28px;
  cursor: pointer;
  display: inline-block;
  transition: var(--transition);
  position: relative;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  background-color: var(--color-black);
}
.btn--primary {
  background-color: var(--color-primary);
}
.btn--secondary {
  background-color: var(--color-secondary);
}
.btn[disabled] {
  background: linear-gradient(86.69deg, #F0F3F6 0%, #F0F3F6 116.5%);
  color: #CACDD6;
  pointer-events: none;
}

@media (max-width: 991.98px) {
  .main-form {
    display: flex;
    flex-direction: column;
  }
}

.form-row:not(:last-child) {
  margin-bottom: 20px;
}

.input {
  padding: 18px 24px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(165, 167, 183, 0.2);
  border-radius: 12px;
  width: 100%;
  outline: none;
  color: var(--white);
  resize: none;
}
.input::placeholder {
  color: #2D3037;
}
.input:focus {
  border-color: var(--white);
}
.input:focus::placeholder {
  font-size: 0;
}
.input--textarea {
  min-height: 182px;
}

.success-message {
  display: none;
  padding: 20px 30px;
  align-items: center;
  justify-content: center;
  background: #12bc12;
  color: var(--white);
  margin: 30px auto;
  border-radius: 4px;
  font-weight: bold;
}

.submit-btn {
  margin-top: 61px;
}
@media (max-width: 991.98px) {
  .submit-btn {
    margin: 43px auto 0 auto;
  }
}
@media (max-width: 767.98px) {
  .submit-btn {
    margin-top: 10px;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.accordion .accordion__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.accordion .accordion__item:not(:last-child) {
  margin-bottom: 20px;
}
.accordion .accordion__item .accordion__btn {
  margin: 0;
  position: relative;
  padding: 16px 28px 16px 48px;
  width: 100%;
  color: var(--color-black);
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  background-color: #ECF7FC;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 20px;
}
.accordion .accordion__item .accordion__btn span {
  display: block;
  position: absolute;
  height: 11px;
  width: 11px;
  left: 21px;
  top: 25px;
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 3px solid var(--color-black);
  border-left: 3px solid var(--color-black);
  transition: var(--transition);
  border-radius: 1px;
  pointer-events: none;
}
.accordion .accordion__item .accordion__panel {
  width: 100%;
  color: var(--color-black);
  background-color: #ECF7FC;
  font-size: 16px;
  line-height: 24px;
  padding: 0 24px 0 48px;
  transition: var(--transition);
  opacity: 0;
  height: auto;
  max-height: 0;
  overflow: hidden;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.accordion .accordion__item.active .accordion__btn {
  color: var(--color-primary);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .accordion__item.active .accordion__btn span {
  transform: translateY(-50%) rotate(135deg);
  border-color: var(--color-primary);
  top: 31px;
}
.accordion .accordion__item.active .accordion__panel {
  padding-bottom: 24px;
  opacity: 1;
  max-height: 500px;
}

/* The container must be positioned relative: */
.dropdown {
  position: relative;
  min-width: 145px;
  width: fit-content;
}

.dropdown select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: var(--color-white);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 34px;
  padding: 12px 38px 12px 16px;
  transition: var(--transition);
}

/* Style the arrow inside the select element: */
.select-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 15px;
  width: 9px;
  height: 9px;
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 3px solid var(--color-primary);
  border-left: 3px solid var(--color-primary);
  transition: var(--transition);
  border-radius: 1px;
  margin-top: -1px;
}

.select-selected.select-arrow-active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  transform: translateY(-50%) rotate(135deg);
  border-color: var(--color-white);
  margin-top: 1px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  cursor: pointer;
}

.select-items div {
  padding: 10px 20px;
  white-space: nowrap;
  background-color: var(--color-white);
  font-size: 16px;
  line-height: 16px;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: var(--color-white);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 0;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.select-items .same-as-selected {
  background-color: var(--color-primary);
  color: var(--color-white);
}

@media (min-width: 992px) {
  .footer {
    padding: 8px 0;
  }
}
@media (max-width: 991.98px) {
  .footer {
    padding: 20px 0 40px 0;
  }
}
.footer__inner {
  display: flex;
}
@media (min-width: 992px) {
  .footer__inner {
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 991.98px) {
  .footer__inner {
    align-items: center;
    flex-direction: column;
  }
}
.footer__copyright {
  font-size: 12px;
  line-height: 18px;
}
@media (max-width: 991.98px) {
  .footer__copyright {
    order: 3;
    text-align: center;
  }
}
.footer__nav {
  list-style-type: none;
}
@media (max-width: 991.98px) {
  .footer__nav {
    display: none;
  }
}
@media (min-width: 992px) {
  .footer__nav {
    display: flex;
    align-items: center;
  }
}
.footer__nav-item:not(:last-child) {
  margin-right: 40px;
}
.footer__nav-link {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-primary);
}
@media (max-width: 991.98px) {
  .footer .footer__nav {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .footer .footer__nav-item {
    margin-right: 0px;
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  .footer .footer__nav-item:last-child {
    margin-bottom: 20px;
  }
}
.footer__logos {
  list-style-type: none;
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .footer__logos {
    order: 2;
    margin-bottom: 24px;
  }
}
.footer__logos-item:not(:last-child) {
  margin-right: 3px;
}
.footer__logos-img {
  width: auto;
  height: auto;
}
@media (max-width: 991.98px) {
  .footer__social {
    order: 1;
    margin-bottom: 24px;
  }
}

.section-main {
  position: relative;
  background-color: var(--color-white);
}
@media (min-width: 992px) {
  .section-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - var(--header-height) - 48px);
    padding: 60px 0;
  }
}
@media (max-width: 991.98px) {
  .section-main {
    min-height: calc(100vh - var(--header-height) - 190px);
  }
}
@media (min-width: 992px) {
  .section-main--gap-large {
    padding: 125px 0 148px 0;
  }
}
@media (max-width: 991.98px) {
  .section-main--gap-large {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-main--gap-mid {
    padding: 95px 0 58px 0;
  }
}
@media (max-width: 991.98px) {
  .section-main--gap-mid {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .section-main--gap-small {
    padding: 59px 0 57px 0;
  }
}
@media (max-width: 991.98px) {
  .section-main--gap-small {
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .section-main--gap-xsmall {
    padding: 34px 0;
  }
}
@media (min-width: 992px) {
  .section-main--gap-none {
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .section-main--gap-none {
    padding: 0;
  }
}
.section-main--colored-bg {
  background-color: #DCE7EC;
}
.section-main--pink-bg {
  background-color: #E6DFD5;
}
@media (min-width: 992px) {
  .section-main--green-bg {
    background-color: #5EA585;
  }
}
@media (max-width: 991.98px) {
  .section-main--green-bg {
    background-color: #DCE7EC;
  }
}
@media (min-width: 992px) {
  .section-main__bg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    object-fit: cover;
  }
  .section-main__bg--right {
    width: auto;
    right: 0;
    left: auto;
  }
  .section-main__bg--left {
    width: auto;
    left: 0;
    right: auto;
  }
}
@media (max-width: 991.98px) {
  .section-main__bg {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
}
@media (min-width: 992px) {
  .section-main__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.section-main__content {
  border-radius: 28px;
  position: relative;
}
@media (min-width: 992px) {
  .section-main__content {
    padding: 40px;
    max-width: 459px;
  }
}
@media (max-width: 991.98px) {
  .section-main__content {
    padding: 28px;
  }
  .section-main__content--mob-gap-lg {
    margin-top: -119px;
  }
  .section-main__content--mob-gap-sm {
    margin-top: -32px;
  }
}
.section-main__content--white {
  background-color: var(--color-white);
}
.section-main__content--opacity {
  background: rgba(255, 255, 255, 0.7);
}
@media (min-width: 992px) {
  .section-main__content--flat {
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .section-main__content--flat {
    padding: 0;
  }
}
.section-main__title {
  max-width: 362px;
}
.section-main__title--colored {
  color: var(--color-primary);
  font-weight: 400;
  margin-bottom: 8px;
}
.section-main__title--white {
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .section-main__title--white {
    color: var(--color-white);
  }
}
@media (max-width: 991.98px) {
  .section-main__title--white {
    margin-top: 20px;
  }
}
.section-main__title--md {
  font-size: 28px;
  line-height: 32px;
}
.section-main__title--sm {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}
.section-main__text {
  margin-top: 20px;
  line-height: 24px;
}
.section-main__text a {
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 15px;
  display: inline-block;
  word-break: break-word;
}
.section-main__btn {
  margin-top: 10px;
}
.section-main__features {
  list-style-type: none;
  margin-top: 20px;
}
@media (min-width: 561px) {
  .section-main__features {
    display: flex;
  }
}
@media (min-width: 561px) {
  .section-main__feature:not(:last-child) {
    margin-right: 20px;
  }
}
@media (max-width: 560.98px) {
  .section-main__feature:not(:last-child) {
    margin-bottom: 20px;
  }
}
.section-main__feature-link {
  border: 2px solid var(--color-primary);
  border-radius: 20px;
  color: var(--color-primary);
  display: flex;
  transition: var(--transition);
}
.section-main__feature-link:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
}
@media (min-width: 561px) {
  .section-main__feature-link {
    text-align: center;
    padding: 18px 19px 12px 19px;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    width: 114px;
  }
  .section-main__feature-link:not(:last-child) {
    margin-right: 20px;
  }
}
@media (max-width: 560.98px) {
  .section-main__feature-link {
    padding: 33px 38px;
    align-items: center;
  }
}
@media (min-width: 561px) {
  .section-main__feature-icon {
    margin-bottom: 7px;
  }
}
@media (max-width: 560.98px) {
  .section-main__feature-icon {
    margin-right: 26px;
  }
}
.section-main__feature-label {
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .team {
    padding: 69px 0 99px 0;
  }
}
@media (max-width: 767.98px) {
  .team {
    padding: 42px 0 59px 0;
  }
}
.team__title {
  font-weight: 600;
  line-height: 32px;
}

.team-grid {
  margin-top: 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
@media (min-width: 768px) {
  .team-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
}
@media (min-width: 1025px) {
  .team-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.team-grid__item {
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
}
.team-grid__img {
  display: flex;
  width: 100%;
  height: 165px;
  object-fit: cover;
  object-position: top;
}
@media (min-width: 561px) {
  .team-grid__img {
    height: 300px;
  }
}
@media (min-width: 768px) {
  .team-grid__img {
    height: 240px;
  }
}
.team-grid__info {
  background-color: #F1F1F1;
  padding: 16px 0;
}
.team-grid__info > *:not(:last-child) {
  margin-bottom: 12px;
}
.team-grid__name {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}
.team-grid__position {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #8C8C8C;
}

.products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 10px;
  align-items: center;
  justify-items: center;
}
.products-grid__item {
  width: auto;
  height: auto;
}

.fullpage-slider {
  width: 100%;
}
@media (min-width: 992px) {
  .fullpage-slider {
    max-height: calc(100vh - var(--header-height) - 48px);
  }
}
.fullpage-slider__wrapper {
  margin-top: -1px;
}
@media (max-width: 991.98px) {
  .fullpage-slider__wrapper {
    display: block;
    height: auto;
  }
}
.fullpage-slider__pagination.swiper-pagination-vertical {
  right: 15px;
}
.fullpage-slider__pagination.swiper-pagination-vertical .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background-color: var(--color-white);
  border: 2px solid var(--color-primary);
  opacity: 1;
  margin: 8px 0;
}
.fullpage-slider__pagination.swiper-pagination-vertical .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.steps {
  list-style-type: none;
}
@media (min-width: 561px) {
  .steps {
    max-width: 360px;
  }
}

.step {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 16px;
}
.step:not(:last-child) {
  margin-bottom: 8px;
}
.step__head {
  color: var(--color-primary);
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.step__body {
  font-size: 16px;
  line-height: 16px;
}

@media (min-width: 768px) {
  .faq {
    padding: 49px 0 137px 0;
  }
}
@media (max-width: 767.98px) {
  .faq {
    padding: 28px 0 103px 0;
  }
}
.faq__title {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .text-section {
    padding: 49px 0 89px 0;
  }
}
@media (max-width: 767.98px) {
  .text-section {
    padding: 28px 0 56px 0;
  }
}
.text-section__title {
  margin-bottom: 20px;
}

.text-content {
  font-size: 16px;
  line-height: 24px;
}
.text-content p:not(:last-of-type) {
  margin-bottom: 28px;
}
.text-content a {
  color: var(--color-primary);
  font-weight: 600;
  text-decoration: underline;
}
.text-content ul {
  margin-bottom: 28px;
}
.text-content ol {
  margin-bottom: 28px;
  list-style: none;
  counter-reset: item;
}
.text-content ol li {
  counter-increment: item;
}
.text-content ol li:before {
  content: counter(item) ".";
  display: inline-block;
  margin-right: 3px;
}
.text-content b, .text-content strong {
  font-weight: 600;
}

.contacts-line {
  font-size: 16px;
  line-height: 24px;
}
.contacts-line:first-of-type {
  margin-top: 8px;
}
.contacts-line:not(:last-child) {
  margin-bottom: 30px;
}
.contacts-line a, .contacts-line span {
  font-weight: 600;
  color: var(--color-primary);
  word-break: break-word;
}

@media (max-width: 991.98px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    padding-top: 42px;
    padding-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .contact-section__inner {
    order: 1;
  }
}
@media (max-width: 991.98px) {
  .contact-section__img-wrap {
    order: 2;
  }
}

.services-dropdown {
  margin-top: 16px;
  margin-bottom: 6px;
}

.area-img {
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
  width: auto;
  height: auto;
}
@media (min-width: 1601px) {
  .area-img {
    width: 800px;
  }
}
@media (max-width: 1600.98px) {
  .area-img {
    width: 500px;
  }
}
@media (max-width: 991.98px) {
  .area-img {
    margin-bottom: 6px;
  }
}

.section-main__right {
  position: relative;
}

@media (max-width: 991.98px) {
  .service-section:not(.service-section--single) {
    background-color: #DCE7EC;
    padding-bottom: 42px;
    margin-top: -1px;
  }
  .service-section:not(.service-section--single) .section-main__content {
    padding: 28px 28px 8px 28px;
    background-color: var(--color-white);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .service-section:not(.service-section--single) .section-main__right {
    background-color: var(--color-white);
    padding: 8px 28px 28px 28px;
    margin-top: -1px;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .service-section--single {
    padding: 82px 0 72px 0;
  }
  .service-section--single .services-dropdown {
    margin-bottom: 0;
  }
  .service-section--single .section-main__btn {
    margin-bottom: 27px;
  }
}

.benefits-section {
  background-color: #E6DFD5;
}
@media (min-width: 1201px) {
  .benefits-section {
    padding-top: 151px;
  }
}
@media (min-width: 992px) {
  .benefits-section {
    padding-top: 92px;
  }
}
@media (max-width: 991.98px) {
  .benefits-section {
    padding-bottom: 78px;
  }
}
@media (min-width: 992px) {
  .benefits-section__container {
    display: flex;
  }
}
.benefits-section__text {
  max-width: 404px;
  font-size: 20px;
  line-height: 32px;
  padding: 0 21px;
}
@media (min-width: 992px) {
  .benefits-section__left {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .benefits-section__right {
    max-width: 332px;
    flex: 1;
  }
}
.benefits-section__title {
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 19px;
}
.benefits-section__img {
  width: 100%;
  height: auto;
}

.benefits-list {
  list-style-type: none;
}
.benefits-list__item {
  padding: 20px;
  border-radius: 16px;
  background-color: var(--color-white);
  font-size: 16px;
  line-height: 20px;
}
.benefits-list__item:not(:last-child) {
  margin-bottom: 16px;
}

@media (min-width: 1201px) {
  .features-section {
    padding-top: 109px;
  }
}
@media (min-width: 992px) {
  .features-section {
    padding-top: 53px;
  }
}
@media (max-width: 991.98px) {
  .features-section {
    padding-top: 40px;
    padding-bottom: 104px;
  }
}
.features-section__container {
  display: flex;
  flex-wrap: wrap;
}
.features-section__title {
  width: 100%;
  flex-basis: 100%;
  line-height: 32px;
}
.features-section__title--bold {
  font-weight: 600;
}
@media (max-width: 991.98px) {
  .features-section__title {
    order: 1;
  }
}
.features-section__text {
  font-size: 14px;
  line-height: 20px;
}
.features-section__text a {
  color: var(--color-secondary);
  font-weight: 600;
}
@media (min-width: 992px) {
  .features-section__left {
    padding-bottom: 88px;
    max-width: 587px;
  }
}
@media (max-width: 991.98px) {
  .features-section__left {
    order: 3;
  }
}
.features-section__right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .features-section__right {
    order: 2;
    flex-basis: 100%;
  }
}
.features-section__img-wrap {
  width: 100%;
  position: relative;
  right: -15px;
}
@media (min-width: 1201px) {
  .features-section__img-wrap {
    margin-left: 100px;
  }
}
@media (max-width: 991.98px) {
  .features-section__img-wrap {
    bottom: -90px;
    margin-top: -90px;
    z-index: -1;
  }
}
.features-section__img {
  width: 100%;
  height: auto;
}

.features-list {
  list-style: none;
  counter-reset: item;
  margin-top: 20px;
  margin-bottom: 16px;
}
.features-list__item {
  background-color: #ECF7FC;
  padding: 28px 20px 20px 20px;
  border-radius: 16px;
  counter-increment: item;
  position: relative;
}
.features-list__item::before {
  content: counter(item);
  display: inline-block;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 20px;
  top: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
}
.features-list__item:not(:last-child) {
  margin-bottom: 16px;
}
.features-list__item-title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  padding-left: 40px;
  margin-bottom: 16px;
}
.features-list__item-text {
  font-size: 16px;
  line-height: 20px;
}
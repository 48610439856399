.text-section {
  @include min-768 {
    padding: 49px 0 89px 0;
  }
  @include max-767 {
    padding: 28px 0 56px 0;
  }
  &__container {

  }
  &__title {
    margin-bottom: 20px;
  }
}
.text-content {
  font-size: 16px;
  line-height: 24px;
  p {
    &:not(:last-of-type) {
      margin-bottom: 28px;
    }
  }
  a {
    color: var(--color-primary);
    font-weight: 600;
    text-decoration: underline;
  }
  ul {
    margin-bottom: 28px;
  }
  ol {
    margin-bottom: 28px;
    list-style: none;
    counter-reset: item;
    li {
      counter-increment: item;
    }
    li:before {
      content: counter(item) '.';
      display: inline-block;
      margin-right: 3px;
    }
  }

  b, strong {
    font-weight: 600;
  }
}

.fullpage-slider {
  width: 100%;
  @include min-992 {
    max-height: calc(100vh - var(--header-height) - 48px);
  }
  &__wrapper {
     margin-top: -1px;
     @include max-991 {
       display: block;
       height: auto;
     }
  }
  &__slide {
    section {

    }
  }
  &__pagination {
    &.swiper-pagination-vertical {
      right: 15px;
      .swiper-pagination-bullet {
        width: 16px;
        height: 16px;
        background-color: var(--color-white);
        border: 2px solid var(--color-primary);
        opacity: 1;
        margin: 8px 0;
        &-active {
          background-color: var(--color-primary);
        }
      }
    }
  }

}

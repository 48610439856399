.section-main {
  position: relative;
  background-color: var(--color-white);
  @include min-992 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - var(--header-height) - 48px);
    padding: 60px 0;
  }
  @include max-991 {
    min-height: calc(100vh - var(--header-height) - 190px);
  }
  &--gap-large {
    @include min-992 {
      padding: 125px 0 148px 0;
    }
    @include max-991 {
      padding-bottom: 80px;
    }
  }
  &--gap-mid {
    @include min-992 {
      padding: 95px 0 58px 0;
    }
    @include max-991 {
      padding-bottom: 80px;
    }
  }
  &--gap-small {
    @include min-992 {
      padding: 59px 0 57px 0;
    }
    @include max-991 {
      padding-bottom: 40px;
    }
  }
  &--gap-xsmall {
    @include min-992 {
      padding: 34px 0;
    }
    @include max-991 {

    }
  }
  &--gap-none {
    @include min-992 {
      padding: 0;
    }
    @include max-991 {
      padding: 0;
    }
  }
  &--colored-bg {
    background-color:  #DCE7EC;
  }
  &--pink-bg {
    background-color: #E6DFD5;
  }
  &--green-bg {
    @include min-992 {
      background-color: #5EA585;
    }
    @include max-991 {
      background-color:  #DCE7EC;
    }
  }
  &__bg {
    @include min-992 {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 0;

      object-fit: cover;

      &--right {
        width: auto;
        right: 0;
        left: auto;
      }
      &--left {
        width: auto;
        left: 0;
        right: auto;
      }
    }
    @include max-991 {
      width: 100%;
      object-fit: cover;
      height: auto;
    }
  }
  &__inner {
    @include max-991 {

    }
    @include min-992 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__content {
    border-radius: 28px;
    position: relative;
    @include min-992 {
      padding: 40px;
      max-width: 459px;
    }
    @include max-991 {
      padding: 28px;

      &--mob-gap-lg {
        margin-top: -119px;
      }
      &--mob-gap-sm {
        margin-top: -32px;
      }
    }
    &--white {
      background-color: var(--color-white);
    }
    &--opacity {
      background: rgba(255, 255, 255, 0.7);
    }
    &--flat {
      @include min-992 {
        padding: 0;
      }
      @include max-991 {
        padding: 0;
      }
    }
  }
  &__title {
    max-width: 362px;
    &--colored {
      color: var(--color-primary);
      font-weight: 400;
      margin-bottom: 8px;
    }
    &--white {
      margin-bottom: 10px;
      @include min-992 {
        color: var(--color-white);
      }
      @include max-991 {
        margin-top: 20px;
      }
    }
    &--md {
      font-size: 28px;
      line-height: 32px;
    }
    &--sm {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }
  }
  &__text {
    margin-top: 20px;
    line-height: 24px;
    a {
      color: var(--color-secondary);
      font-weight: 600;
      font-size: 15px;
      display: inline-block;
      word-break: break-word;
    }
  }
  &__btn {
    margin-top: 10px;
  }
  &__features {
    list-style-type: none;
    margin-top: 20px;
    @include min-561 {
      display: flex;
    }
  }
  &__feature {
    &:not(:last-child) {
      @include min-561 {
        margin-right: 20px;
      }
      @include max-560 {
        margin-bottom: 20px;
      }
    }
    &-link {
      border: 2px solid var(--color-primary);
      border-radius: 20px;
      color: var(--color-primary);
      display: flex;
      transition: var(--transition);
      &:hover {
        color: var(--color-white);
        background-color: var(--color-primary);
      }
      @include min-561 {
        text-align: center;
        padding: 18px 19px 12px 19px;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        width: 114px;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      @include max-560 {
        padding: 33px 38px;
        align-items: center;
      }
    }
    &-icon {
      @include min-561 {
        margin-bottom: 7px;
      }
      @include max-560 {
        margin-right: 26px;
      }
    }
    &-label {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

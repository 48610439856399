.main-form {
  @include max-991 {
    display: flex;
    flex-direction: column;
  }
}
.form-row {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.input {
  padding: 18px 24px;
  background-color: rgba(255, 255, 255, .2);
  border: 1px solid rgba(165, 167, 183, 0.2);
  border-radius: 12px;
  width: 100%;
  outline: none;
  color: var(--white);
  resize: none;
  &::placeholder {
    color: #2D3037;
  }
  &:focus {
    border-color: var(--white);
  }
  &:focus::placeholder {
    font-size: 0;
  }
  &--text {

  }
  &--textarea {
    min-height: 182px;
  }
}

.success-message {
  display: none;
  padding: 20px 30px;
  align-items: center;
  justify-content: center;
  background: #12bc12;
  color: var(--white);
  margin: 30px auto;
  border-radius: 4px;
  font-weight: bold;
}

.submit-btn {
  margin-top: 61px;
  @include max-991 {
    margin: 43px auto 0 auto;
  }
  @include max-767 {
    margin-top: 10px;
  }
}

.features-section {
  @include min-1201 {
    padding-top: 109px;
  }
  @include min-992 {
    padding-top: 53px;
  }
  @include max-991 {
    padding-top: 40px;
    padding-bottom: 104px;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
  }
  &__title {
    width: 100%;
    flex-basis: 100%;
    line-height: 32px;
    &--bold {
      font-weight: 600;
    }
    @include max-991 {
      order: 1;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    a {
      color: var(--color-secondary);
      font-weight: 600;
    }
  }
  &__left {
    @include min-992 {
      padding-bottom: 88px;
      max-width: 587px;
    }
    @include max-991 {
      order: 3;
    }
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include max-991 {
      order: 2;
      flex-basis: 100%;
    }
  }
  &__img-wrap {
    width: 100%;
    position: relative;
    right:-15px;
    @include min-1201 {
      margin-left: 100px;
    }
    @include max-991 {
      bottom: -90px;
      margin-top: -90px;
      z-index: -1;
    }
  }
  &__img {
    width: 100%;
    height: auto;
  }
}

.features-list {
  list-style: none;
  counter-reset: item;
  margin-top: 20px;
  margin-bottom: 16px;
  &__item {
    background-color: #ECF7FC;
    padding: 28px 20px 20px 20px;
    border-radius: 16px;
    counter-increment: item;
    position: relative;
    &::before {
      content: counter(item);
      display: inline-block;
      width: 32px;
      height: 32px;
      position: absolute;
      left: 20px;
      top: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      background-color: var(--color-primary);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &-title {
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      padding-left: 40px;

      margin-bottom: 16px;
    }
    &-text {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

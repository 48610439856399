.steps {
  list-style-type: none;
  @include min-561 {
    max-width: 360px;
  }
}
.step {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 16px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &__head {
    color: var(--color-primary);
    margin-bottom: 8px;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
  &__body {
    font-size: 16px;
    line-height: 16px;
  }
}
